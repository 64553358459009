// @flow

import { Button, Form, Input } from "antd"
import React, { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"

import AgreementText from "../AgreementText"
import Link from "../Link"
import { contactUs } from "../../redux/actions/miscActions"
import { getContactUsState } from "../../redux/selectors"

// function handleSubmit(e: Object, props: Object, dispatch: Function) {
//   e.preventDefault();
//
//   const {form} = props;
//   form.validateFields((err, values) => {
//     if (!err) {
//       dispatch(contactUs(values.email, values.message, form.resetFields));
//     }
//   });
// }

const ContactUs = (props: Object) => {
  const dispatch = useDispatch()
  const sending = useSelector(s => getContactUsState(s).sending)

  const [form] = Form.useForm()
  //const { getFieldDecorator } = props.form;

  return (
    <div id={props.id}>
      <h2 className="ant-typography text-center">Questions?</h2>
      <p className="text-center">
        Please check our <Link to="/faq">FAQ</Link> or get in touch
      </p>
      <div className="top-space-2x"></div>
      <Form
        form={form}
        name="contactUs"
        layout="vertical"
        onFinish={values =>
          dispatch(contactUs(values.email, values.message, form.resetFields))
        }
      >
        <Form.Item
          name="email"
          label="Email"
          hasFeedback
          rules={[
            {
              type: "email",
              message: "Please input a valid email address",
            },
            {
              required: true,
              whitespace: true,
              message: "Please input your email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="message"
          label="Message"
          hasFeedback
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please input your message",
            },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 5, maxRows: 30 }} />
        </Form.Item>
        <Form.Item>
          <AgreementText btnTitle="Send message" />
          <div className="top-space"></div>
          <Button
            className="wide-btn"
            size="large"
            shape="round"
            htmlType="submit"
            block
            loading={sending}
          >
            {sending ? "Sending..." : "Send message"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ContactUs
